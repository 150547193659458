<template>
  <div>
    <b-nav card-header pills class="m-0 mt-1">
      <b-nav-item
        :to="{ name: pendingTabName }"
        exact
        exact-active-class="active"
        :link-classes="['sub-tab-nav', 'px-3', bgTabsNavs]"
        >Pending</b-nav-item
      >
      <b-nav-item
        :to="{ name: inQueueTabName }"
        exact
        exact-active-class="active"
        :link-classes="['sub-tab-nav', 'px-3', bgTabsNavs]"
        >In Queue</b-nav-item
      >
      <b-nav-item
        :to="{ name: returnedTabName }"
        exact
        exact-active-class="active"
        :link-classes="['sub-tab-nav', 'px-3', bgTabsNavs]"
        >Returned
        <span v-if="cReturnedDeptLetters > 0 && moduleId !== 28" class="ml-2">
          <feather-icon
            icon
            :badge="cReturnedDeptLetters > 99 ? '99+' : cReturnedDeptLetters"
            badge-classes="badge-danger"
          /> </span
      ></b-nav-item>
    </b-nav>
    <b-card class="border-top-info border-3 border-table-radius px-0">
      <LetterRoundTable />
    </b-card>
  </div>
</template>
  <script>
import LetterRoundTable from "@/views/commons/components/letters-v2/components/LettersDisputeTable.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    LetterRoundTable,
  },
  computed: {
    ...mapGetters({
      cReturnedDeptLetters:
        "SpecialistsDigitalRoundLettersStore/G_COUNTER_DEPT_RETURNED_LETTERS",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },

    pendingTabName() {
      const { routeToSendPending } =
        this.moduleId != 5
          ? this.$route.matched[2].meta
          : this.$route.matched[4].meta;
      return routeToSendPending;
    },
    inQueueTabName() {
      const { routeToSendInQueue } =
        this.moduleId != 5
          ? this.$route.matched[2].meta
          : this.$route.matched[4].meta;
      return routeToSendInQueue;
    },

    returnedTabName() {
      const { routeToSendReturned } =
        this.moduleId != 5
          ? this.$route.matched[2].meta
          : this.$route.matched[4].meta;
      return routeToSendReturned;
    },
  },
};
</script>
